import { Component } from 'preact';
import { Router } from 'preact-router';


// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import SignIn from '../routes/signin';

export default class App extends Component {
	componentWillMount() {
		if (window.location.hostname == "localhost" && window.location.port == 8080) {
			sessionStorage.setItem('rs_api_url', 'https://localhost:44312');
			sessionStorage.setItem('rs_asset_url', '/')
		} else {
			sessionStorage.setItem('rs_asset_url', '/app/')
		}		
	}

	render({ }, { }) { 
		return (
			<div id="app">
				<div class="bg-img"></div>
				<Router>
					<Home path="/" />
					<SignIn path="/inloggen" />
				</Router>
			</div> 
		)
	}
} 

